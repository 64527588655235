import { Layout } from 'antd';
import React, { useCallback, VFC } from 'react';
import { OrderSummary } from './components/OrderSummary';

const { Header: PageHeader } = Layout;

export const Header: VFC = () => {
  return (
    <PageHeader>
      <div />
    </PageHeader>
  );
};
