import { useMutation } from 'react-query';
import { omitBy } from 'lodash';
import { QueryNames } from './constants';
import { RegisterProspectParams } from './types';
import apiCalls from './calls';

export function useRegisterProspect() {
  const { data, isLoading, error, mutate } = useMutation(
    QueryNames.RegisterProspect,
    (body: RegisterProspectParams) => {
      return apiCalls.registerProspect(
        omitBy(body, item => !item) as RegisterProspectParams,
      );
    },
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data: data?.data?.data as any,
    isLoading,
    error,
    registerProspect: mutate,
  };
}

export function useRegisterProspectEmployee() {
  const { data, isLoading, error, mutate } = useMutation(
    QueryNames.RegisterProspectEmployee,
    (body: RegisterProspectParams) => {
      return apiCalls.registerProspectEmployee(
        omitBy(body, item => !item) as RegisterProspectParams,
      );
    },
    {
      onError: (e: Error) => {},
      onSettled: () => {},
      retry: 2,
    },
  );
  return {
    data: data?.data?.data as any,
    isLoading,
    error,
    registerProspectEmployee: mutate,
  };
}
