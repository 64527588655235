import React, { useState, useEffect } from 'react';
import { FormInstance, InputNumber } from 'antd';
import moment from 'moment';
import { FORM_FIELD_VALUES_ENUM } from '../types';

type Props = {
  form: FormInstance;
};

export const DateOfBirth = ({ form }: Props) => {
  const [dayValue, setDayValue] = useState<number | null>();
  const [monthValue, setMonthValue] = useState<number | null>();
  const [yearValue, setYearValue] = useState<number | null>();

  useEffect(() => {
    const addedValue = [monthValue, dayValue, yearValue].some(item => !!item);
    const dob = `${monthValue}-${dayValue}-${yearValue}`;
    if (!moment(dob, 'MM-DD-YYYY').isValid() && addedValue) {
      form.validateFields([FORM_FIELD_VALUES_ENUM.DOB]);
    } else if (moment(dob, 'MM-DD-YYYY').isValid() && addedValue) {
      form.setFieldsValue({
        [FORM_FIELD_VALUES_ENUM.DOB]: moment(dob, 'MM-DD-YYYY').format(
          'MM-DD-YYYY',
        ),
      });
    }
  }, [dayValue, monthValue, yearValue]);

  return (
    <div className="flex flex-row">
      <div className="flex-col pr-2">
        <div>Month</div>
        <InputNumber
          value={monthValue}
          onChange={setMonthValue}
          controls={false}
          minLength={1}
          max={12}
          min={1}
          maxLength={2}
          placeholder="11"
        />
      </div>
      <div className="flex-col pr-2">
        <div>Day</div>
        <InputNumber
          value={dayValue}
          onChange={setDayValue}
          controls={false}
          minLength={1}
          maxLength={2}
          placeholder="22"
          min={1}
          max={31}
        />
      </div>
      <div className="flex-col">
        <div>Year</div>
        <InputNumber
          value={yearValue}
          onChange={setYearValue}
          controls={false}
          minLength={4}
          maxLength={4}
          min={1900}
          max={2010}
          placeholder="1968"
        />
      </div>
    </div>
  );
};
