import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { App } from './App';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
Sentry.init({
  dsn: 'https://561658041f9e46eeaf1df1b2ab5045e7@o463846.ingest.sentry.io/4505431932928000',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  tracesSampleRate: 0.5,
});
const render = () => {
  ReactDOM.render(<App />, MOUNT_NODE);
};

render();
