import React, { useCallback } from 'react';
import { Image, Layout } from 'antd';
import { images } from '../../constants';

const { Content, Footer, Header } = Layout;

export const EmployerNotFound = () => {
  const renderHeader = useCallback(() => {
    return (
      <Header
        style={{ backgroundColor: 'transparent', paddingInline: 0 }}
        className="mb-24"
      >
        <Image
          className="left-0"
          height="100px"
          src={images.bastionGoldenLogo}
          preview={false}
        />
      </Header>
    );
  }, []);

  return (
    <div className="h-screen">
      <Layout className="p-12 h-screen">
        {renderHeader()}
        <Content>
          <div className="p-12 rounded-2xl bg-white border-gray-300 border-2">
            <div className="text-2xl font-bold pb-5">
              {" We couldn't find the company that you searched for."}
            </div>
            <div className="">
              Please double-check the company name that you entered in the URL
              and try again.
            </div>
          </div>
        </Content>
      </Layout>
      <Footer
        // color="#E4C18A"
        style={{ backgroundColor: '#DCDCE2' }}
        className="w-full p-5 items-center text-center"
      >
        <div className="center text-gray-600">© Bastion Health 2023</div>
      </Footer>
    </div>
  );
};
