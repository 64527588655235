import { find } from 'lodash';
import {
  AILMENTS,
  PACKAGE_UNIQUE_CODE,
} from '../../../services/package.static';

export enum OPTION_INPUT_TYPES {
  NUMBER = 'numberInput',
  SINGLE_SELECT = 'singleSelect',
  MULTI_SELECT_DROPDOWN = 'MULTI_SELECT_DROPDOWN',
  SUB_QUESTIONS = 'subQuestions',
  FREE_TEXT = 'freeText',
}

const PregnancyOptions = [
  {
    type: OPTION_INPUT_TYPES.NUMBER,
    label: 'Count',
  },
  {
    type: OPTION_INPUT_TYPES.NUMBER,
    label: 'To term',
  },
  {
    type: OPTION_INPUT_TYPES.NUMBER,
    label: 'Children',
  },
  {
    type: OPTION_INPUT_TYPES.NUMBER,
    label: 'Boys',
  },
  {
    type: OPTION_INPUT_TYPES.NUMBER,
    label: 'Girls',
  },
];

export const QUESTIONNAIRE_FORMS = [
  {
    type: '',
    question: 'Workup HX',
    options: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No previous pregnancies with current partner',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No previous pregnancies with past partner',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: "No current partner's pregnancies with past partner",
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Partner has not had fertility work up',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: "No current partner's fertility tests",
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No spermatogenesis environment exposure',
      },
      {
        label: 'Previous pregnancies with current partner',
        type: OPTION_INPUT_TYPES.SUB_QUESTIONS,
        options: PregnancyOptions,
      },
    ],
  },
];

export const FERTILITY_QUESTIONNAIRE = [
  {
    type: '',
    question: 'Workup HX',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No previous pregnancies with current partner',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No previous pregnancies with past partner',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: "No current partner's pregnancies with past partner",
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Partner has not had fertility work up',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: "No current partner's fertility tests",
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No spermatogenesis environment exposure',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SUB_QUESTIONS,
        label: 'Previous pregnancies with current partner',
        subQuestions: [
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of pregnancies with current partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of pregnancies carried to term with current partner',
            options: ['1', '2', '3', '4', '5', '6', '7'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of children with current partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of boys with current partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of girls with current partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
        ],
      },
      {
        type: OPTION_INPUT_TYPES.SUB_QUESTIONS,
        label: 'Pregnancies with past partner',
        subQuestions: [
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of pregnancies with past partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of pregnancies carried to term with past partner',
            options: ['1', '2', '3', '4', '5', '6', '7'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of children with past partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of boys with past partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
          {
            type: OPTION_INPUT_TYPES.NUMBER,
            label: 'Number of girls with past partner',
            options: ['1', '2', '3', '4', '5', '6', '7', '8'],
            optional: true,
          },
        ],
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Current partner’s OB GYN history: tubal obstruction',
        options: [
          'unknown',
          'tubal obstruction',
          'endometriosis',
          'PID',
          'ovarian stimulation',
          'irregular menstruation',
        ],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label:
          'Previous fertility evaluation on (date) with (ovulation status)',
        freeText: '10 days',
        options: ['normal', 'abnormal', 'unknown'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Out of bed immediately post intercourse',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Family history of infertility',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.FREE_TEXT,
        label: 'Onset of puberty',
        freeText: '7 years',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Patient wears',
        options: ['briefs', 'boxers', 'boxer briefs'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.FREE_TEXT,
        label: 'Previous fertility treatments date:',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label:
          'Patient has had fertility workup and is taking (medication) for (duration)',
        freeText: '7',
        options: ['weeks', 'months', 'years'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Has had previous semen analysis',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Patient known to take hot baths',
        options: ['take hot baths', 'use the sauna', 'use the jacuzzi'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label:
          'Positive for history of testicular trauma treated by orchiectomy',
        options: ['orchiectomy', 'orchioplexy', 'conservative', 'other'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Has known exposure to chemicals or pesticides dyes',
        options: [
          'benzene',
          'dyes',
          'pesticides',
          'DDT',
          'automobile painting',
          'house painting',
          'radiation exposure',
          'chemotherapy',
        ],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Previous GU surgery bladder/prostate',
        options: [
          'bladder/prostate',
          'hydrocele',
          'spermatocelectomy',
          'inguinal hernia',
          'orchiectomy',
          'orchioplexy',
          'retroperitoneal node dissection',
          'torsion',
          'other',
        ],
        optional: true,
      },
      // ... Include further findings as needed
    ],
  },
  {
    type: '',
    question: 'STI Hx',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label:
          'Sexually transmitted disease patient has no known history of having STIs or Patient has a history',
        options: [
          'patient has no known history of having STIs',
          'Patient has a history of STIs',
        ],
        optional: true,
      },
      // ... Include further findings as needed
    ],
  },
  {
    type: '',
    question: 'Lifestyle',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Intercourse frequency',
        options: ['multiple times a day', 'weekly', 'monthly'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Sexual activity (not intercourse) frequency',
        options: ['multiple times a day', 'daily', 'weekly'],
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Lubricants used with intercourse',
        options: [
          'K-Y jelly',
          'vaseline',
          'oil',
          'scented oil',
          'astroglide',
          'lubriflex',
          'keri lotion',
          'surgery',
          'lube',
          'saliva',
        ],
        optional: true,
      },
      // ... Include drug use and other lifestyle choices as needed
    ],
  },
  {
    type: '',
    question: 'Associated Symptoms Described By Patient',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No blood in semen',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No impotence',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Normal libido',
        optional: true,
      },
      // ... Include further findings as needed
    ],
  },
  // ... Include further sentences and questions as needed
];

export const SEXUAL_HEALTH_QUESTIONNAIRE = [
  {
    type: '',
    question: 'Quality:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'inability to obtain adequate erection',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'inability to maintain adequate erection',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'lack of ejaculation',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'retrograde ejaculation',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'premature ejaculation',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Severity:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'mild',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'moderate',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'severe',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Duration:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'constant',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'intermittent',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Onset/Timing:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'sudden',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'gradual',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.FREE_TEXT,
        label: 'weeks: ',
        freeText: '2',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.FREE_TEXT,
        label: 'months: ',
        freeText: '2',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.FREE_TEXT,
        label: 'years: ',
        freeText: '2',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Context:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no family members have same problem',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'does not prevent satisfactory intercourse',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'does not prevent normal activity',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'does not interfere with social activities',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'can work',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'has not experienced same problem as before',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'prior treatment: Viagra',
        optional: true, // NOTE: Options like Viagra, Levitra, etc. might be converted into a selectable list if required.
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'response to treatment: no response',
        optional: true, // NOTE: Options like no response, suboptimal response, etc. might be converted into a selectable list if required.
      },
    ],
  },
  {
    type: '',
    question: 'Alleviating Factors:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'medications',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Aggravating Factors:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'alcohol',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'caffeine intake',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'intercourse',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'medications',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Associated Symptoms:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no depression',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no anxiety',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no endocrine abnormalities',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no diabetes',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no hypertension',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no hypogonadism',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no dyslipidemia',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no coronary artery disease',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no peripheral vascular disease',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'no hypothyroidism',
        optional: true,
      },
      /* Positive ones */
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'depression',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'anxiety',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'endocrine abnormalities',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'diabetes',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'hypertension',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'hypogonadism',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'dyslipidemia',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'coronary artery disease',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'peripheral vascular disease',
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        optional: true,
        label: 'hypothyroidism',
      },
    ],
  },
  // ... Include further questions and subQuestions as needed
];
export const PROSTATE_SCREEN_QUESTIONNAIRE = [
  {
    type: '',
    question: 'PSA history:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.FREE_TEXT,
        label: 'PSA history details',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'History of prostate issues',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'History of prostate biopsy',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'Family history of prostate cancer',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'History of prostate infection',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'History of urinary tract infection',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'UTI',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'ED',
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Current or Past Medications',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.MULTI_SELECT_DROPDOWN,
        label: 'Medications taken',
        options: [
          'alpha blockers',
          'analgesics',
          'antibiotics',
          'antispasmodics',
          'Avodart',
          'Flomax',
          'Proscar',
          'uroxatral',
        ],
        optional: true,
      },
    ],
  },
  {
    type: '',
    question: 'Associated Symptoms:',
    subQuestions: [
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No abdominal pain',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No back pain',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No chills',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No constipation',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No diarrhea',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No groin pain',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No hematospermia',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No hematuria',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No hesitancy',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No impotence',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No loss of libido',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No nausea',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No straining stream',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No stress incontinence',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No urgency',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No urine odor',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No vomiting',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No weight loss',
        optional: true,
      },
      /* Positive ones */
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'abdominal pain',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'back pain',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'chills',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'constipation',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'diarrhea',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'groin pain',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'hematospermia',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'hematuria',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'hesitancy',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'impotence',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'loss of libido',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'nausea',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No straining stream',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'No stress incontinence',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'urgency',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'urine odor',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'vomiting',
        optional: true,
      },
      {
        type: OPTION_INPUT_TYPES.SINGLE_SELECT,
        label: 'weight loss',
        optional: true,
      },
    ],
  },
  // ... Include further sentences and questions as needed
];

export const NurseOnboardingQuestionnaire = {
  [PACKAGE_UNIQUE_CODE.sexualHealth]: {
    questionnaire: SEXUAL_HEALTH_QUESTIONNAIRE,
    title: find(AILMENTS, { value: PACKAGE_UNIQUE_CODE.sexualHealth })?.name,
  },
  [PACKAGE_UNIQUE_CODE.maleInfertility]: {
    questionnaire: FERTILITY_QUESTIONNAIRE,
    title: find(AILMENTS, { value: PACKAGE_UNIQUE_CODE.maleInfertility })?.name,
  },
  [PACKAGE_UNIQUE_CODE.prostateEnlargement]: {
    questionnaire: [],
    title: find(AILMENTS, {
      value: PACKAGE_UNIQUE_CODE.prostateEnlargement,
    })?.name,
  },
  [PACKAGE_UNIQUE_CODE.prostateCancerScreening]: {
    questionnaire: PROSTATE_SCREEN_QUESTIONNAIRE,
    title: find(AILMENTS, {
      value: PACKAGE_UNIQUE_CODE.prostateCancerScreening,
    })?.name,
  },
  [PACKAGE_UNIQUE_CODE.hormonalHealth]: {
    questionnaire: SEXUAL_HEALTH_QUESTIONNAIRE,
    title: find(AILMENTS, {
      value: PACKAGE_UNIQUE_CODE.hormonalHealth,
    })?.name,
  },
  [PACKAGE_UNIQUE_CODE.uti]: {
    questionnaire: [],
    title: find(AILMENTS, {
      value: PACKAGE_UNIQUE_CODE.uti,
    })?.name,
  },
  [PACKAGE_UNIQUE_CODE.bladderCancerScreening]: {
    questionnaire: [],
    title: 'Unavailable at this moment',
  },
};
